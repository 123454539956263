/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */
@import "devicon.min.css";
@import "platform-container.css";

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl my-6 text-blue-900;
  }
  h2 {
    @apply text-2xl font-semibold mb-4 mt-8 text-blue-900;
  }
  h3 {
    @apply text-lg font-semibold;
  }
  a {
    @apply text-blue-500 underline;
  }
}
